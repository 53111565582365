import React from "react";
import "./TaxiListComponent.css"
import data from "./data/data.json"
import { withTranslation } from 'react-i18next';
import ImageSLiderComponent from "../imageSliderComponent/ImageSLiderComponent";

class TaxiListComponent extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            data: data,
            makina:23
        }
    }


    render() {
        const { t } = this.props;
        return (
            <React.Fragment>
                <section className="taxi-style-one">
                    <div className="container">
                        <div className="block-title text-center">
                            <div className="dot-line"></div>
                            {/* <p>{t('taxiListCompo.TAXI_TITLE_P')}</p> */}
                            <h2>{t('taxiListCompo.TAXI_TITLE_H2')}</h2>
                        </div>

                        <ul className="nav nav-tabs tab-title" role="tablist">
                            {this.state.data.TAXI_TITLE_CATEGORY.map((dt, index) =>
                                <li className="nav-item" key={Math.random().toString()}>
                                    <a className={"nav-link " + (index === 0 ? 'active' : '')} href={"#" + (dt === "4by4" ? 'forByFor' : dt)} role="tab" data-toggle="tab">{dt}</a>
                                </li>
                            )}
                        </ul>
                        <div className="tab-content">
                            <div role="tabpanel" className="tab-pane show active  animated fadeInUp" id="Economic">
                                <div className="row">
                                    <div className="col-lg-4 col-md-6 col-sm-12"></div>
                                    {this.state.data.TAXI_TITLE_LIST.filter(cat => cat.TAXI_TITLE_LIST_KATEGORY === "Economic").map((dt, index) =>
                                        <div className="col-lg-4" key={index}>
                                            <div className="single-taxi-one">
                                                <div className="inner-content">
                                                    <img className="taxiCategoryPhoto" src={process.env.PUBLIC_URL + dt.TAXI_TITLE_LIST_PHOTO} alt="Awesome" />
                                                    {/* <div className="icon-block">
                                                    <i className="conexi-icon-bmw"></i>
                                                </div> */}
                                                    <br />
                                                    <br />
                                                    <br />
                                                    {/* <h3>{dt.TAXI_TITLE_LIST_MODEL}</h3> */}
                                                    <ul className="feature-list">
                                                        <li>
                                                            <span className="name-line">{t('taxiListCompo.TAXI_TITLE_LIST_RATE')}</span>
                                                            <span className="price-line">{dt.TAXI_TITLE_LIST_RATE_PRICE}</span>
                                                        </li>
                                                        <li>
                                                            <span className="name-line">{t('taxiListCompo.TAXI_TITLE_LIST_RATE_KM')}</span>
                                                            <span className="price-line">{dt.TAXI_TITLE_LIST_RATE_PRICE_KM}</span>
                                                        </li>
                                                        <li>
                                                            <span className="name-line">{t('taxiListCompo.TAXI_TITLE_LIST_RATE_PASSENGER')}</span>
                                                            <span className="price-line">{dt.TAXI_TITLE_LIST_RATE_PRICE_PASSENGER_NR}</span>
                                                        </li>
                                                    </ul>
                                                    <a href="/" className="book-taxi-btn">{t('taxiListCompo.TAXI_TITLE_A')}</a>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                </div>
                            </div>
                            <div role="tabpanel" className="tab-pane animated fadeInUp" id="Standart">
                                <div className="row">
                                    <div className="col-lg-4 col-md-6 col-sm-12"></div>
                                    {this.state.data.TAXI_TITLE_LIST.filter(cat => cat.TAXI_TITLE_LIST_KATEGORY === "Standart").map((dt, index) =>
                                        <div className="col-lg-4" key={index}>
                                            <div className="single-taxi-one">
                                                <div className="inner-content">
                                                    <img className="taxiCategoryPhoto" src={process.env.PUBLIC_URL + dt.TAXI_TITLE_LIST_PHOTO} alt="Awesome" />
                                                    {/* <div className="icon-block">
                                                    <i className="conexi-icon-bmw"></i>
                                                </div> */}
                                                    <br />
                                                    <br />
                                                    <br />
                                                    {/* <h3>{dt.TAXI_TITLE_LIST_MODEL}</h3> */}
                                                    <ul className="feature-list">
                                                        <li>
                                                            <span className="name-line">{t('taxiListCompo.TAXI_TITLE_LIST_RATE')}</span>
                                                            <span className="price-line">{dt.TAXI_TITLE_LIST_RATE_PRICE}</span>
                                                        </li>
                                                        <li>
                                                            <span className="name-line">{t('taxiListCompo.TAXI_TITLE_LIST_RATE_KM')}</span>
                                                            <span className="price-line">{dt.TAXI_TITLE_LIST_RATE_PRICE_KM}</span>
                                                        </li>
                                                        <li>
                                                            <span className="name-line">{t('taxiListCompo.TAXI_TITLE_LIST_RATE_PASSENGER')}</span>
                                                            <span className="price-line">{dt.TAXI_TITLE_LIST_RATE_PRICE_PASSENGER_NR}</span>
                                                        </li>
                                                    </ul>
                                                    <a href="/" className="book-taxi-btn">{t('taxiListCompo.TAXI_TITLE_A')}</a>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                </div>
                            </div>
                            <div role="tabpanel" className="tab-pane animated fadeInUp" id="Van">
                                <div className="row">
                                    <div className="col-lg-4 col-md-6 col-sm-12"></div>
                                    {this.state.data.TAXI_TITLE_LIST.filter(cat => cat.TAXI_TITLE_LIST_KATEGORY === "Van").map((dt, index) =>
                                        <div className="col-lg-4" key={index}>
                                            <div className="single-taxi-one">
                                                <div className="inner-content">
                                                    <img className="taxiCategoryPhoto" src={process.env.PUBLIC_URL + dt.TAXI_TITLE_LIST_PHOTO} alt="Awesome" />
                                                    {/* <div className="icon-block">
                                                    <i className="conexi-icon-bmw"></i>
                                                </div> */}
                                                    <br />
                                                    <br />
                                                    <br />
                                                    {/* <h3>{dt.TAXI_TITLE_LIST_MODEL}</h3> */}
                                                    <ul className="feature-list">
                                                        <li>
                                                            <span className="name-line">{t('taxiListCompo.TAXI_TITLE_LIST_RATE')}</span>
                                                            <span className="price-line">{dt.TAXI_TITLE_LIST_RATE_PRICE}</span>
                                                        </li>
                                                        <li>
                                                            <span className="name-line">{t('taxiListCompo.TAXI_TITLE_LIST_RATE_KM')}</span>
                                                            <span className="price-line">{dt.TAXI_TITLE_LIST_RATE_PRICE_KM}</span>
                                                        </li>
                                                        <li>
                                                            <span className="name-line">{t('taxiListCompo.TAXI_TITLE_LIST_RATE_PASSENGER')}</span>
                                                            <span className="price-line">{dt.TAXI_TITLE_LIST_RATE_PRICE_PASSENGER_NR}</span>
                                                        </li>
                                                    </ul>
                                                    <a href="/" className="book-taxi-btn">{t('taxiListCompo.TAXI_TITLE_A')}</a>
                                                </div>
                                            </div>
                                        </div>
                                    )}</div>
                            </div>
                            <div role="tabpanel" className="tab-pane animated fadeInUp" id="Vip">
                                <div className="row">
                                    <div className="col-lg-4 col-md-6 col-sm-12"></div>
                                    {this.state.data.TAXI_TITLE_LIST.filter(cat => cat.TAXI_TITLE_LIST_KATEGORY === "Vip").map((dt, index) =>
                                        <div className="col-lg-4" key={index}>
                                            <div className="single-taxi-one">
                                                <div className="inner-content">
                                                    <img className="taxiCategoryPhoto" src={process.env.PUBLIC_URL + dt.TAXI_TITLE_LIST_PHOTO} alt="Awesome" />
                                                    {/* <div className="icon-block">
                                                    <i className="conexi-icon-bmw"></i>
                                                </div> */}
                                                    <br />
                                                    <br />
                                                    <br />
                                                    {/* <h3>{dt.TAXI_TITLE_LIST_MODEL}</h3> */}
                                                    <ul className="feature-list">
                                                        <li>
                                                            <span className="name-line">{t('taxiListCompo.TAXI_TITLE_LIST_RATE')}</span>
                                                            <span className="price-line">{dt.TAXI_TITLE_LIST_RATE_PRICE}</span>
                                                        </li>
                                                        <li>
                                                            <span className="name-line">{t('taxiListCompo.TAXI_TITLE_LIST_RATE_KM')}</span>
                                                            <span className="price-line">{dt.TAXI_TITLE_LIST_RATE_PRICE_KM}</span>
                                                        </li>
                                                        <li>
                                                            <span className="name-line">{t('taxiListCompo.TAXI_TITLE_LIST_RATE_PASSENGER')}</span>
                                                            <span className="price-line">{dt.TAXI_TITLE_LIST_RATE_PRICE_PASSENGER_NR}</span>
                                                        </li>
                                                    </ul>
                                                    <a href="/" className="book-taxi-btn">{t('taxiListCompo.TAXI_TITLE_A')}</a>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                </div>
                            </div>
                            <div role="tabpanel" className="tab-pane animated fadeInUp" id="forByFor">
                                <div className="row">
                                    <div className="col-lg-4 col-md-6 col-sm-12"></div>
                                    {this.state.data.TAXI_TITLE_LIST.filter(cat => cat.TAXI_TITLE_LIST_KATEGORY === "4by4").map((dt, index) =>
                                        <div className="col-lg-4" key={index}>
                                            <div className="single-taxi-one">
                                                <div className="inner-content">
                                                    <img className="taxiCategoryPhoto" src={process.env.PUBLIC_URL + dt.TAXI_TITLE_LIST_PHOTO} alt="Awesome" />
                                                    {/* <div className="icon-block">
                                                    <i className="conexi-icon-bmw"></i>
                                                </div> */}
                                                    <br />
                                                    <br />
                                                    <br />
                                                    {/* <h3>{dt.TAXI_TITLE_LIST_MODEL}</h3> */}
                                                    <ul className="feature-list">
                                                        <li>
                                                            <span className="name-line">{t('taxiListCompo.TAXI_TITLE_LIST_RATE')}</span>
                                                            <span className="price-line">{dt.TAXI_TITLE_LIST_RATE_PRICE}</span>
                                                        </li>
                                                        <li>
                                                            <span className="name-line">{t('taxiListCompo.TAXI_TITLE_LIST_RATE_KM')}</span>
                                                            <span className="price-line">{dt.TAXI_TITLE_LIST_RATE_PRICE_KM}</span>
                                                        </li>
                                                        <li>
                                                            <span className="name-line">{t('taxiListCompo.TAXI_TITLE_LIST_RATE_PASSENGER')}</span>
                                                            <span className="price-line">{dt.TAXI_TITLE_LIST_RATE_PRICE_PASSENGER_NR}</span>
                                                        </li>
                                                    </ul>
                                                    <a href="/" className="book-taxi-btn">{t('taxiListCompo.TAXI_TITLE_A')}</a>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div role="tabpanel" className="tab-pane animated fadeInUp" id="Minibus">
                                <div className="row">
                                    <div className="col-lg-4 col-md-6 col-sm-12"></div>
                                    {this.state.data.TAXI_TITLE_LIST.filter(cat => cat.TAXI_TITLE_LIST_KATEGORY === "Minibus").map((dt, index) =>
                                        <div className="col-lg-4" key={index}>
                                            <div className="single-taxi-one">
                                                <div className="inner-content">
                                                    <img className="taxiCategoryPhoto" src={process.env.PUBLIC_URL + dt.TAXI_TITLE_LIST_PHOTO} alt="Awesome" />
                                                    {/* <div className="icon-block">
                                                    <i className="conexi-icon-bmw"></i>
                                                </div> */}
                                                    <br />
                                                    <br />
                                                    <br />
                                                    {/* {/* {/* <h3>{dt.TAXI_TITLE_LIST_MODEL}</h3> */}
                                                    <ul className="feature-list">
                                                        <li>
                                                            <span className="name-line">{t('taxiListCompo.TAXI_TITLE_LIST_RATE')}</span>
                                                            <span className="price-line">{dt.TAXI_TITLE_LIST_RATE_PRICE}</span>
                                                        </li>
                                                        <li>
                                                            <span className="name-line">{t('taxiListCompo.TAXI_TITLE_LIST_RATE_KM')}</span>
                                                            <span className="price-line">{dt.TAXI_TITLE_LIST_RATE_PRICE_KM}</span>
                                                        </li>
                                                        <li>
                                                            <span className="name-line">{t('taxiListCompo.TAXI_TITLE_LIST_RATE_PASSENGER')}</span>
                                                            <span className="price-line">{dt.TAXI_TITLE_LIST_RATE_PRICE_PASSENGER_NR}</span>
                                                        </li>
                                                    </ul>
                                                    <a href="/" className="book-taxi-btn">{t('taxiListCompo.TAXI_TITLE_A')}</a>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div role="tabpanel" className="tab-pane animated fadeInUp" id="Bus">
                                <div className="row">
                                    <div className="col-lg-4 col-md-6 col-sm-12"></div>
                                    {this.state.data.TAXI_TITLE_LIST.filter(cat => cat.TAXI_TITLE_LIST_KATEGORY === "Bus").map((dt, index) =>
                                        <div className="col-lg-4" key={index}>
                                            <div className="single-taxi-one">
                                                <div className="inner-content">
                                                    <img className="taxiCategoryPhoto" src={process.env.PUBLIC_URL + dt.TAXI_TITLE_LIST_PHOTO} alt="Awesome" />
                                                    {/* <div className="icon-block">
                                                    <i className="conexi-icon-bmw"></i>
                                                </div> */}
                                                    <br />
                                                    <br />
                                                    <br />
                                                    {/* {/* <h3>{dt.TAXI_TITLE_LIST_MODEL}</h3> */}
                                                    <ul className="feature-list">
                                                        <li>
                                                            <span className="name-line">{t('taxiListCompo.TAXI_TITLE_LIST_RATE')}</span>
                                                            <span className="price-line">{dt.TAXI_TITLE_LIST_RATE_PRICE}</span>
                                                        </li>
                                                        <li>
                                                            <span className="name-line">{t('taxiListCompo.TAXI_TITLE_LIST_RATE_KM')}</span>
                                                            <span className="price-line">{dt.TAXI_TITLE_LIST_RATE_PRICE_KM}</span>
                                                        </li>
                                                        <li>
                                                            <span className="name-line">{t('taxiListCompo.TAXI_TITLE_LIST_RATE_PASSENGER')}</span>
                                                            <span className="price-line">{dt.TAXI_TITLE_LIST_RATE_PRICE_PASSENGER_NR}</span>
                                                        </li>
                                                    </ul>
                                                    <a href="/" className="book-taxi-btn">{t('taxiListCompo.TAXI_TITLE_A')}</a>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <ImageSLiderComponent images={this.state.makina} />
            </React.Fragment>
        )
    }
}
export default withTranslation()(TaxiListComponent);

