import React from "react";
import { Link } from 'react-router-dom';
import "./FooterComponent.css"
import footerIcon3 from "./images/favicon-32x32.png"
import { withTranslation } from 'react-i18next';
class FooterComponent extends React.Component {

    constructor(props) {
        super(props)
        this.state = {}
    }


    render() {
        const { t } = this.props;
        return (
            <React.Fragment>
                <footer className="site-footer">
                    <div className="upper-footer">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="footer-widget about-widget">
                                        <div className="widget-title">
                                            <h3>{t('footerCompo.FOOTER_TITLE_H3')}</h3>
                                        </div>
                                        <p>{t('footerCompo.FOOTER_TITLE_P')} </p>
                                        <div className="social-block">
                                            <a rel="noopener noreferrer"
                                                href="mailto:info@upstaxi.com?subject=Information about ....&body=Hello Ups'taxi Team,\n\n"><i
                                                    className="fa fa-envelope"></i></a>
                                            <a rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/upstaxi"><i
                                                className="fa fa-facebook-f"></i></a>
                                            <a rel="noopener noreferrer" target="_blank"
                                                href="https://www.youtube.com/channel/UCODKKtgLlOGPLgSxn3mCTQQ/featured"><i
                                                    className="fa fa-youtube-play"></i></a>
                                            <a rel="noopener noreferrer" href="https://www.instagram.com/upstaxi/"><i
                                                className="fa fa-instagram"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="footer-widget">
                                        <div className="widget-title">
                                            <h3>{t('footerCompo.FOOTER_TITLE_LINK_H3')}</h3>
                                        </div>
                                        <ul className="link-lists">
                                            <li><Link to="/">{t('navbar.home')}</Link></li>
                                            <li><Link to="/about">{t('navbar.about')}</Link></li>
                                            <li><Link to="/taxilist">{t('navbar.Fleet')}</Link></li>
                                            <li><Link to="/driverlist">{t('navbar.drivers')}</Link></li>
                                            <li><Link to="/contact">{t('navbar.contact')}</Link></li>
                                            <li><Link to="/how-it-work">{t('navbar.howitwork')}</Link></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="footer-widget">
                                        <div className="widget-title">
                                            <h3>{t('footerCompo.FOOTER_TITLE_CONTACT_H3')}</h3>
                                        </div>
                                        <p>Bulevardi Skënderbeu,4000 <br /> {t('footerCompo.FOOTER_TITLE_CONTACT_P')}</p>
                                        <ul className="contact-infos">
                                            <li><i className="fa fa-envelope"></i>info@upstaxi.com</li>
                                            <li><i style={{fontSize:"23px"}} className="fa fa-mobile"></i> Mobile WhatsApp, Viber  (+355) 672249999</li>
                                            {/* <li><i className="fab fa-viber"></i> (+355) 672249999</li>
                                            <li><i className="fa fa-whatsapp"></i> (+355) 672249999</li> */}

                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="bottom-footer">
                        <div className="container">
                            <div className="inner-container">
                                <div className="left-block">
                                    <a href="/" className="footer-logo"><img src={footerIcon3}
                                        alt="Awesome " /></a>
                                    <span>&copy; {(new Date().getFullYear())} <a href="/">upstaxi.com</a></span>
                                </div>
                                <div className="right-block">
                                    <ul className="link-lists">
                                        {/* <li><a href="#">Terms of Use</a></li> */}
                                        <li><Link to="/privacy">{t('footerCompo.FOOTER_TITLE_CONTACT_PRIVACY')}</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </React.Fragment>
        )
    }
}
export default withTranslation()(FooterComponent);

