import React from "react";
import "./driverListComponent.css"
import data from "./data/data.json"
import Carousel from "react-elastic-carousel";
class DriverListComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: data,
            breakPoints: [
                { width: 1, itemsToShow: 1 },
                { width: 550, itemsToShow: 2 },
                { width: 768, itemsToShow: 3 },
                { width: 1200, itemsToShow: 4 }
            ]
        };
    }


    render() {
        return (
            <section className="team-style-one team-page">
                <div className="container">
                    <div className="row">
                        <Carousel ref={ref => (this.carousel = ref)}
                            itemsToShow={3} enableMouseSwipe={true}
                            enableTilt={true} enableAutoPlay
                            autoPlaySpeed={2000}
                            onChange={(currentItem, pageIndex) =>{
                                console.log("Item current==>",currentItem)
                                console.log("index current==>",pageIndex)
                            }
                              }
                            // easing="cubic-bezier(1,.15,.55,1.54)"
                            // tiltEasing="cubic-bezier(0.110, 1, 1.000, 0.210)"
                            transitionMs={700} breakPoints={this.state.breakPoints}>
                            {this.state.data.DRIVER_LIST_ALL.map((dt, index) =>
                                <div className="col-lg-12" key={index}>
                                    <div className="single-team-one">
                                        <div className="polaroid">
                                            <img className="image-size" src={process.env.PUBLIC_URL + dt.DRIVER_LIST_PHOTO} alt="5 Terre" />
                                            <div className="container">
                                                <h3>{dt.DRIVER_LIST_NAME}</h3>
                                                <p>{dt.DRIVER_LIST_PROFESION}</p>
                                            </div>
                                        </div>
                                        {/* <div className="image-block">
                                        <img  className="rounded-circle" src={process.env.PUBLIC_URL + dt.DRIVER_LIST_PHOTO} alt="Awesome" />
                                    </div>
                                    <div className="text-block">
                                        <h3>{dt.DRIVER_LIST_NAME}</h3>
                                        <p>{dt.DRIVER_LIST_PROFESION}</p>
                                        {/* <div className="social-block">
                                            <a href="#"><i className="fa fa-twitter"></i></a>
                                            <a href="#"><i className="fa fa-facebook-f"></i></a>
                                            <a href="#"><i className="fa fa-youtube-play"></i></a>
                                        </div> 
                                    </div> */}
                                    </div>
                                </div>


                            )}

                        </Carousel>

                    </div>
                </div>
            </section>
        );
    }
}
export default DriverListComponent;

