import React from 'react';
import './TaxiList.css';
import TopBarComponent from '../../components/topBarComponent/TopBarComponent';
import NavBarComponent from '../../components/navBarComponent/NavBarComponent';
import MainBannerComponent from '../../components/mainBannerComponent/MainBannerComponent';
import imageTaxList from "./images/DSC_3288.JPG"
import TaxiListComponent from '../../components/TaxiComponent/TaxiListComponent';
import PeopleSaidComponent from '../../components/PeopleSaidComponent/PeopleSaidComponent';
import AvaiableComponent from '../../components/AvaiableComponent/AvaiableComponent';
import FooterComponent from '../../components/FooterComponent/FooterComponent';
function TaxiList() {


  return (
    <div className="page-wrapper">
      <header className="site-header header-one">
        <TopBarComponent />
        <NavBarComponent />
      </header>
      <MainBannerComponent page={imageTaxList} description="List of our Taxi" />     
      <TaxiListComponent />
      <PeopleSaidComponent />
      <AvaiableComponent />
      <FooterComponent />
    </div>
  );
}

export default TaxiList;
