import React from "react";
import "./MainBannerComponent.css"
import { withTranslation } from 'react-i18next';
import iosPhoto from "./images/512x512.png"
import androidPhoto from "./images/and512x512.png"
class TopBarComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      opacity:this.props.opacity
    };
  }


  render() {
    const { t } = this.props;
    return (
      <section className="inner-banner" style={{ background: `#fff url(${this.props.page}) center center no-repeat `, backgroundSize: "cover"}}>
        <div className="container">
          <ul className="thm-breadcrumb">
            <li><a href="/">{t('mainBanner.link')}</a></li>
            {/* <li><span className="sep"></span></li>
            <li><span className="page-title"></span></li> */}
          </ul>
          <div>
            <a rel="noopener noreferrer" target="_blank" href="https://apps.apple.com/us/app/ups-taxi/id1477752265?ls=1"><img src={iosPhoto} style={{height:"150px",width:"160px",marginRight:"5px"}} alt="Download on the App Store" border="0" /></a>
            <a rel="noopener noreferrer" target="_blank" href="https://play.google.com/store/apps/details?id=com.upstaxi.taxi.rider"><img src={androidPhoto} style={{height:"150px",width:"160px",marginLeft:"5px"}} alt="Get it on Google Play" border="0" /></a>
          </div>
        </div>
      </section>
    )
  }
}
export default withTranslation()(TopBarComponent);

