import React from "react";
import "./BookComponents.css"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
// import BookingBackgroun from "./images/DSC_3677.JPG"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import { withTranslation } from 'react-i18next';
import Select from 'react-select';
import Autocomplete from 'react-google-autocomplete';

const options = [
    { value: 'Economic', label: 'Economic' },
    { value: 'Standart', label: 'Standart' },
    { value: 'Vip', label: 'Vip' },
    { value: 'Van', label: 'Van' },
    { value: '4x4', label: '4x4' },
    { value: 'Minibus', label: 'Minibus' },
    { value: 'Bus', label: 'Bus' },
];
class BookComponents extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            fullName: '',
            emailPerson: '',
            nrPassenger: '',
            pickUpAddress: '',
            dropOffAddress: '',
            textarea: '',
            kategory: { value: 'Standart', label: 'Standart' },
            dateTime: new Date()

        };
        this.onChange = this.onChange.bind(this);
        this.onHandleSubmit = this.onHandleSubmit.bind(this);
    }


    onChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }


    handleValidation() {
        let validationFields = {};
        let formIsValid = true;

        //Name
        if (!this.state.fullName) {
            formIsValid = false;
            validationFields["fullName"] = "Cannot be empty";
        }

        // if (typeof this.state.name !== "undefined") {
        //     if (!this.state.name.match(/^[a-zA-Z]+$/)) {
        //         formIsValid = false;
        //         validationFields["name"] = "Only letters";
        //     }
        // }

        //nrPassenger
        if (!this.state.nrPassenger) {
            formIsValid = false;
            validationFields["nrPassenger"] = "Cannot be empty";
        }

        if (typeof this.state.nrPassenger !== "undefined") {
            if (!this.state.nrPassenger.match(/^[0-9]+$/)) {
                formIsValid = false;
                validationFields["nrPassenger"] = "Only letters";
            }
        }

        //dropOffAdress
        if (!this.state.dropOffAddress) {
            formIsValid = false;
            validationFields["dropOffAddress"] = "Cannot be empty";
        }

        // if (typeof this.state.dropOffAdress !== "undefined") {
        //     if (!this.state.dropOffAdress.match(/^[a-zA-Z]+$/)) {
        //         formIsValid = false;
        //         validationFields["dropOffAdress"] = "Only letters";
        //     }
        // }
        //pickUpAdress
        if (!this.state.pickUpAddress) {
            formIsValid = false;
            validationFields["pickUpAddress"] = "Cannot be empty";
        }

        // if (typeof this.state.pickUpAdress !== "undefined") {
        //     if (!this.state.pickUpAdress.match(/^[a-zA-Z]+$/)) {
        //         formIsValid = false;
        //         validationFields["pickUpAdress"] = "Only letters";
        //     }
        // }

        //Email
        if (!this.state.emailPerson) {
            formIsValid = false;
            validationFields["emailPerson"] = "Cannot be empty";
        }

        if (typeof this.state.emailPerson !== "undefined") {
            let lastAtPos = this.state.emailPerson.lastIndexOf('@');
            let lastDotPos = this.state.emailPerson.lastIndexOf('.');

            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && this.state.emailPerson.indexOf('@@') === -1 && lastDotPos > 2 && (this.state.emailPerson.length - lastDotPos) > 2)) {
                formIsValid = false;
                validationFields["emailPerson"] = "Email is not valid";
            }
        }

        // this.setState({ validationFields: validationFields });
        return formIsValid;
    }

    onHandleSubmit(event) {
        event.preventDefault();

        if (this.handleValidation()) {
            axios.post('https://upstaxi.com/send', this.state)
                .then(function (response) {
                    console.log(response);
                    toast.success('Email has been sent,We will contact you ', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        // closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                })
                .catch(function (error) {
                    console.log(error);
                    toast.error('Something Was wrong , Email has been not sent ', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        // closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                });
        } else {
            toast.error('You must fill all Fields Before ', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                // closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }
    handleChange = selectedOption => {
        this.setState(
            { kategory: selectedOption },
            () => console.log(`Option selected:`, this.state.kategory)
        );
    };
    render() {
        const { t } = this.props;
        return (
            <section className="book-ride-one">
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                {/* Same as */}
                <ToastContainer />
                {/* <img src={BookingBackgroun} className="footer-bg" alt="Footer" /> */}
                <div className="container">
                    <div className="row">
                        <div className="col-lg-1">
                        </div>
                        <div className="col-lg-10">
                            <div className="content-block">
                                <div className="block-title mb-0">
                                    {/* <div className="dot-line"></div> */}
                                    <h2 className="light">{t('booking.h2')}</h2>
                                </div>
                            </div>
                            <br />
                            <form onSubmit={this.onHandleSubmit} className="booking-form-one">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="input-holder">
                                            <input type="text" name="fullName" value={this.state.fullName} onChange={this.onChange} placeholder={t('booking.fullName')} />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="input-holder">
                                            <input type="email" name="emailPerson" value={this.state.emailPerson} onChange={this.onChange} placeholder={t('booking.email')} />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="input-holder">
                                            <input type="number" name="nrPassenger" value={this.state.nrPassenger} onChange={this.onChange} placeholder={t('booking.passenger')} />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="input-holder">
                                            {/* <input type="text" name="name" placeholder="Select date" /> */}
                                            <DatePicker
                                                selected={this.state.dateTime}
                                                minDate={new Date()}
                                                onChange={(date) => this.setState({ dateTime: date })}
                                                showTimeSelect
                                                timeFormat="HH:mm"
                                                timeIntervals={15}
                                                timeCaption="time"
                                                dateFormat="MMMM d, yyyy h:mm aa"
                                            />
                                            {/* <i className="conexi-icon-small-calendar"></i> */}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="input-holder">
                                            <Autocomplete
                                                style={{ width: '100%' }}
                                                onPlaceSelected={(place) => {
                                                    this.setState({
                                                        pickUpAddress:place.formatted_address
                                                    });
                                                }}
                                                placeholder={t('booking.pickupAddress')}
                                                types={['(regions)']}
                                                componentRestrictions={{ country: ["al","me","mk","hr","gr","xk"] }}
                                            />
                                            {/* <input type="text" name="pickUpAddress" value={this.state.pickUpAddress} onChange={this.onChange} placeholder={t('booking.pickupAddress')} /> */}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="input-holder">
                                        <Autocomplete
                                                style={{ width: '100%' }}
                                                onPlaceSelected={(place) => {
                                                    this.setState({
                                                        dropOffAddress:place.formatted_address
                                                    });
                                                }}
                                                placeholder={t('booking.dropoffAddress')}
                                                types={['(regions)']}
                                                componentRestrictions={{ country: ["al","me","mk","hr","gr","xk"] }}
                                            />
                                            {/* <input type="text" name="dropOffAddress" value={this.state.dropOffAddress} onChange={this.onChange} placeholder={t('booking.dropoffAddress')} /> */}
                                        </div>
                                    </div>  <div className="col-lg-6">
                                        <div className="input-holder">
                                            <Select className="selectpicker"
                                                defaultValue={options[1]}
                                                value={this.state.kategory}
                                                onChange={this.handleChange}
                                                options={options}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="input-holder">
                                            <textarea value={this.state.textarea} name="textarea" onChange={this.onChange} placeholder={t('booking.textarea')} />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="input-holder">
                                            <button type="submit">{t('booking.button')}</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
export default withTranslation()(BookComponents);

