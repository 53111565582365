import React from "react";
// import { Link } from 'react-router-dom';
import "./AvaiableComponent.css"
import { withTranslation } from 'react-i18next';
class AvaiableComponent extends React.Component {

    constructor(props) {
        super(props)
    }


    render() {
        const { t } = this.props;
        return (
            <React.Fragment>
                <section className="cta-style-one text-center">
                    <div className="container">
                        <h3>{t('availableCompo.CALL_P')}</h3>
                        <h4 style={{ whiteSpace: "pre-line" }}>{t('availableCompo.CALL_DESC')}</h4>
                        {/* <Link className="cta-btn" to="/taxilist">List of Taxi</Link> */}
                        <a href="tel:+35522249999" className="cta-btn">{t('availableCompo.CALL_BUTTON')}</a>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}
export default withTranslation()(AvaiableComponent);

