import React, { useState } from 'react';
import './HelpPage.css';
import TopBarComponent from '../../components/topBarComponent/TopBarComponent';
import MainBannerComponent from '../../components/mainBannerComponent/MainBannerComponent';
import imageHome from "./images/DSC_3288.JPG"
import FooterComponent from '../../components/FooterComponent/FooterComponent';
import NavBarComponent from '../../components/navBarComponent/NavBarComponent';
import Accordion from "../../components/accordioncomponent/accordionComponent"

function HelpPage() {

  const [selectedIndex, setSelectedIndex] = useState(0)

  return (
    <div className="page-wrapper">
      <header className="site-header header-one">
        <TopBarComponent />
        <NavBarComponent />
      </header>
      <MainBannerComponent page={imageHome} opacity={0.5} description="Welcome :)" />
      <div class="jumbotron">
        {/* <h1 class="display-4">Help Center!</h1> */}
        <h1 class="display-4">Qendra e ndihmes!</h1>
        {/* <p class="lead">Welcome to Up's Taxi’s Help Center,
        where you’ll find answers how to use the Application for Client and Drivers.
        Before emailing or calling us,
         take a look through, as the help you need is likely already here waiting for you.</p> */}
        <p class="lead">Mirësevini në Qendrën e Ndihmës të UPs Taxi <br />
        Ketu do të gjeni përgjigje se si të përdorni Aplikacionin për Klient dhe Shofer.
        Para se të na dërgoni email ose të na telefononi, hidhni një vështrim, pasi ndihma që ju nevojitet ka të ngjarë tashmë këtu duke pritur për ju.</p>
        <hr class="my-4" />

        <Accordion
          className="accordion"
          selectedIndex={selectedIndex}
        >
          <div
            data-header="Aplikacioni per Klientat"
            className="accordion-item"
          >
            <p>
              Duke hapur nje llogari ne  ju beheni pjese e se ardhmes.<br /><br />
              Duke u bere pjese e <b>UPs Taxi</b> ju mund te porosisni  automjete transporti per udhetare me kapacitete te ndryshme  4, 6, 8, 20 vende nepermjet smartphoneve, tableteve apo PC-ve.<br /><br />
              Ju Lutem perpara se te filloni perdorimin e aplikacionit sigurohu qe te keni:  Bateri ne mjaftueshme ne cellular, Internet (keshillohet 3G ose me i larte) dhe te aktivizoni sensorin GPS.<br />
              Pasi jeni rregjistruar me pare ne faqen Rregjstrohu, ju keni mundesine te logoheni ne faqen Hyr, duke perdorur te dhenat qe ju keni zgjedhur (User/Password), dhe me pas fillon procesi i porosise.<br /><br />

            </p>
            <br /><br />
            <h4>POROSI E RE</h4>
            <br />
            <h6>Ju mund te zgjidhni Pikenisjen dhe Destinacionin ne 3 menyra:</h6>
            <ul class="list-group list-group-flush">
              <li class="list-group-item">1) 	Duke klikuar tek butoni  (P) Nisja, Gps do ju gjeje menjehere vendndodhjen tuaj duke e
              caktuar si pikenisje (Kontrollo nese GPS ka gjetur sakte vendndodhjen tuaj, keshillohet qe gjate ketij procesi
                te jeni nen qiell te hapur)</li>
              <li class="list-group-item">2)	Duke Zvogluar- Zmadhuar, levizur Lart- Posht Harten per te gjetur Pozicionin
              tuaj dhe me nje klik te gishtit mbi  pozicionin e deshiruar  keni zgjedhur koordinatat tuaja .
               (me nje klik tjeter e ndryshon pozicionin)</li>
              <li class="list-group-item">3)	Duke shkruar me tekst pozicionin (psh TIRANA HOTEL SHERATON, ose SHKODER HOTEL COLOSSEO)  mund te klikosh mbi sugjerimet e mundshme mbi ate te kerkuarin.</li>
              <li class="list-group-item">
                -	Zgjidh daten:  SOT ose  ndryshoje ate per nje dite tjeter<br />
              -	Zgjidh Oren :  Ndrysho oren dhe minutat duke klikuar mbi shiqetat Lart-Poshte tek fusha e ores dhe tek fusha e  minutave<br />
              -	Numri i Personave :  Me ndihmen  butonave   +    -  sakteso sa persona do udhetojne<br />
              -	Numri i bagazheve :  Me ndihmen  butonave   +    -   sakteso sa valixhe keni<br /></li>
            </ul>
            <p>
              Pasi keni kontrolluar per saktesine e informacionit te dhene KLIKO  OK<br />
              Tani sju mbetet gje tjeter vetem te prisni …<br /><br />
              Ne kete moment Sistemi ne baze te pikes se nisjes se caktuar nga ju fillon kerkimin per te gjetur shoferin me te afert.<br />
              Porosia juaj merr statusin  KERKOHET SHOFERI.  + ( harta e pikenisjes suaj)<br /><br />
              Kur njeri nga shoferet ka pranuar kerkesen tuaj, porosia merr statusin  PRANUAR NGA SHOFERI + (Nje tingull zanor, Targa e makines dhe Numri i tel te Shoferit).<br />
              Kur Shoferi niset porosia merr Statusin SHOFERI ESHTE RRUGES + (Nje tingull zanor , Targa e Makines, Numri i tel te shoferit , distanca ne metra e shoferit larg jush, harta ne te cilen shikohet pikenisja juaj dhe makina e cila leviz ne drejtimin tuaj).<br /><br />
              Ne momentin qe shoferi arrin afer jush Porosia merr statusin SHOFERI PRET KLIENTIN + (Nje tingull zanor nje bori makine, Targa e Makines, Numri i tel te shoferit , distanca ne metra e shoferit larg jush, harta ne te cilen shikohet pikenisja juaj dhe makina e cila ndodhet disa metra afer jush.<br /><br />
              Kur Klienti ka hypur ne bord, Porosia merr statusin  NE UDHETIM + (Nje tingull zanor , harta ku shfaqet piknisja juaj dhe makina qe ju po udhetoni per ne destinacion).<br />
              Kur ju keni mberritur ne destinacion, Porosia merr Statusin  KRYER +  (Nje tingull zanor +  Shiko Udhetimin).
              (tek Shiko udhetimin jane dy Tab. TAB historiku qe paraqet me dt, ore, adresa, krijimin e porosise, nisjen e shoferit,
              hypja e klientin ne makine, arritja ne destinacion dhe distacen e pershkuar ne km. Ndersa Tab Harta e Intinerartit paraqet ne
              harte gjithe levizjen qe ka bere makina qe nga moment qe eshte marre porosia e deri ne destinacion<br /><br />

            </p>
            <br /><br />
            <h4>Avantazhet</h4>
            <br /><br />
           Porosisni taxi-ne tuaj me nje klik.
          Ky aplikacion ju mundeson Ju, te porosisni taxi ne cast, ose paraporosisni taxi per nje kohe te mevonshme.<br />
          Ju mundeson te jepni adresen me nje klik, pa pasur  nevoje te shpjegoni pa fund se ku ndodheni<br />
          Taxia me afer jush do marre kerkesen tuaj<br />
          Orjentimi i perafert i cmimit te udhetimit tuaj<br />
          Gjurmimi i taxi-se qe po vjen drejt jush<br />
          Njoftim ne kohe reale i statusit te shoferit  (u nis, arriti, filloi udhetimi, mbaroi udhetimi)<br /><br />
            <br />
          Ky aplikacion ju mundeson Ju te porosisne taxi-ne, ne menyren me komode te mundshme pa u shqetesuar nga telefonatat me
          dispatcher apo taxiste duke shpjeguar pa fund adresen se ku ndodheni apo ku doni te shkoni. Duke klikuar tek butoni NISJA,
          sistemi do diktoje menjehere vendndodhjen tuaj. Zgjidh daten dhe oren e nisjes, numrin  e personave dhe bagazheve dhe OK.
          Atehere nuk ju mbetet tjeter vec te prisni dhe shikoni Taxine qe vjen drejt jush.
          <br /><br /><br /><br />
          </div>
          <div
            data-header="Aplikacione per Shoferat"
            className="accordion-item"
          >
            <p>
              Duke hapur nje llogari ne  ju beheni pjese e se ardhmes.<br /><br />
              Duke u bere pjese e UPs taxi,<br /><br />
            -  Ju jeni nje hap perpara!<br />
            - Tashme Ju jeni shoferi i preferuar i klientit!<br />
            -  Ky eshte sherbimi i Gjenerates se Re!<br />
            </p>
            <br /><br />
            <p>
              Aplikacioni UPs Taxi eshte ndertuar per te lidhur ne kohe reale pasagjerin me shoferin.
              Ky aplikacion eshte i programuar per tu perdorur nga nje shofer i vetepunesuar,
              por gjithashtu edhe per kompani transporti per pasagjer dhe logjistik me shume shofer.
              Kompania pervec App-it merr ne paketen e saj edhe disa avantazhe te tjera si: Panel i i dispatch,
              Panel  i Admin te cilat lehtesojne menaxhimin e shoferave nga dispatch dhe menaxhimin e kompanise nga administratori.
            </p>
            <br /><br />
            <p>
              Klienti nepermjet llogarise se tij ne UPs Taxi krijon nje porosi ku percaktohet pikenisja,
              destinacioni, dita, ora, nr i personave dhe numri i valixheve.
              Kjo porosi hidhet ne ‘’Ankand’’ duke i shkuar shoferit me te afert me pikenisjen e klientit.
              Shoferi do marr nje njoftim viziv dhe zanor ne App-in e tij. Shoferi ka disa sekonda ne dispozicion per
              ta pranuar porosine, nqs shoferi  e refuzon porosine apo nuk pergjigjet porosia i kalon shoferit tjeter me te afert.
            </p>
            <br /><br />
            <h4>INSTALIMI DHE RREGJISTRIMI I UPS TAXI DRIVER:</h4>
            <br /><br />
            <p>
              Per te Shkarkuar aplikacionin e shoferit duhet te shkojme te dyqani i telefonit, dmth tek PlayStore ne nje telefon Android dhe AppStore nga nje telefone Iphone
            </p>
            <br />
            <br />
            <p>1-Shkojme te Store i telefonit dhe shkruajme UPSTAXI DRIVER (shoferi)</p>
            <p>2-Klikojme mbi Aplikacionin dhe e instalojme duke kliuar <b>INSTALL</b> </p>
            <p>3-Presim disa caste dhe e hapim me butonin <b>OPEN</b></p>
            <p>4-Lejojme shfaqjen e vendndodhjes nepermjet butonit <b>ALLOW</b></p>
            <p>5-Zgjedhim gjuhen  e perdorimit  Shqip, duke kliuar mbi flamurin Shqiptar ose ate Amerikan per Anglisht  </p>
            <p>6-Klikojme mbi butonin portokalli LOGIN/SIGN UP</p>
            <p>7-Vendosim nr e celularit qe duam te perdorim, pa numrin 0 ne fillim te numrit. Shembull:  672249999</p>
            <p>8-Klikojme butonin portokalli  VIRIFY PHONE NUMBER</p>
            <p>9-Pas pak castesh ne telefon do na vije nje SMS me kodin e verifikimit, qe ne shume raste hyn Automatikisht</p>
            <br />
            <br />
            <h4>Plotesojme Te dhenat si shofer:</h4>
            <br /><br />
            <p>Perpara se te fillojme plotesimin e te dhenave si shofer, sugjerojme te fotografoni Makinen tuaj, Vehten tuaj, Patenten, Deshmine e Taksisti, Lejen e Qarkullimit per ti perdorur gjate rregjistrimit</p>
            <br /><br />
            <ul class="list-group list-group-flush">
              <li class="list-group-item"><b>A</b>  Klikojme tek fusha me shume ngjyra (Ne tabelen dialoguese qe na fshaqet Klikojme ALLOW), do na shfaqet galeria ose do na hapet kamera per te bere foto.<br />
               Zgjedhim nje foto te makines nga afer dhe klikojme butonin  <i className="fa fa-check" style={{ fontSize: "24px" }}></i> </li>
              <li class="list-group-item"><b>B</b> Klikojme tek fotoja e shoferit  <i className="fa fa-user-circle" style={{ fontSize: "24px" }}></i>      dhe zgjedhim nje foto Personale te Shoferit dhe klikojme butonin  <i className="fa fa-check" style={{ fontSize: "24px" }}></i> . </li>
              <li class="list-group-item"><b>C</b> Vendosim E-Mailin nqs ka,  EMER, MBIEMER, GJINIA, NGJYRA E MAKINES, TARGA, VITI PRODHIMIT, CERTIFIKATA OSE LICENSA,<br /> NR I LLOGARISE (nr i iban ose lere bosh), ADRESA (Vendi ku punon, psh Posta Nr 8 Tirana).<br /></li>
              <li class="list-group-item"><b>D</b> Zgjedhim Kategorine ne te cilen duam te punojme, EKONOMIKE 65L/Km, STANDART 75L/Km , LUX 90L/Km,  Van 120L/Km etj.</li>
              <li class="list-group-item"><b>E</b> Shto Dokument (Butoni Portokalli) Dhe zgjidh nga Galeria foton e Patentes, Deshmise se Taksise, dhe lejen e Qarkullimit ose Fotografoji ato ne cast dhe klikojme butonin  <i className="fa fa-check" style={{ fontSize: "24px" }}></i>.</li>
              <li class="list-group-item"><b>F</b>Tani do shfaqet nje mesazh qe njofton qe kerkesa eshte derguar dhe do presim deri ne shqyrtimin dhe miratimin e kerkeses nga ana e stafit te UpsTaxi. (Zakonisht kjo procedure zgjate disa ore).</li>
              <li class="list-group-item"><b>G</b>Nqs ne ekran ju shfaqet mesazhi ''Your Profile.... '' nuk duhet te kryer asnje veprim  pasi kerkesa nuk eshte miratuar ende, prandaj duhet te kontrollohet pas disa minutash.</li>
              <li class="list-group-item"><b>H</b>Kur miratimi eshte pranuar, aplikacioni shfaq harten me ne qender nje makine, dhe eshte gadi per pune.</li>
            </ul>
            <br /><br />
            <h4>PERDORIMI I APLIKACIONIT TE SHOFERIT (UPsTaxi Driver):</h4>
            <br /><br />
            <ul class="list-group list-group-flush">
              <li class="list-group-item"><b>1</b>  Pasi Stafi i UPsTAxi ka miratuar kerkesen tende, aplikacioni do hapet dhe do shfaqet harta dhe makina e verdhe ne qender te saj. Tani jeni i gatshem per pune. </li>
              <li class="list-group-item"><b>2</b> KUJDES, Butoni Lart Djathtas duhet te jete gjithmone i hapur. Duhet ta kontrollojme here pas here pasi nqs do jete i mbyllur porosite nuk vijne nga Klienti. </li>
              <li class="list-group-item"><b>3</b> MENU, Djathtas Lart   <br /><br />
                <br />- KREDIT, eshte balanca e Shoferit e cila mbushet ose shtohet nqs klineti paguan me aplikacion. Kujdes! Nqs Balanca eshte 0 ose -, nuk lejon ardhje e porosive.
                  <br />- UDHETIME, Ketu shfaqen te gjitha detajet e udhetimeve e kryera, te anulluara etj.
                  <br />- STATISTIKA, Javore, Ditore, Javore per te Ardhurat, Sherbimet, Vleresimet. Gjithashtu shoferi mund ti kerkoje UPsTaxi per te marre pagesen e akumuluar nga udhetimet me kliente, duke Klikuar butonin KERKESA E PAGESES. Sugjerohet qe vlera te kaloje 1000 leke.
                  <br />- PORTOFOLI, Shfaq Balancen aktuale te lekeve to akumuluara nga klientet, si dhe rimbushjen e llogarise se shoferit nepemjet paypal apo menyrave te tjera finaciare qe shfaqen ne aplikacion
                  <br />- TRANSAKSIONE, Shfaq te gjitha veprimet finaciare ndermjet Shoferit, Klientit dhe Kompanise. Udhetimet e paguara cash, Udhetime te paguara nepermjet aplikacionit, Komisionin per kompanine etj.

              </li>
              <li class="list-group-item"><b>4</b>NDIHME   <br /><br />
                <br />- RRETH, Shfaqen versioni i Aplikacionit, E-Mail, Wesite, Telefoni dhe kushtet e perdorimit
                   <br />- DALJE, Sherben per te dale
                   <br />
              </li>
            </ul>
            <br /><br />


            <h4>SI TE PRANOJME NJE POROSI QE NA VJEN NE APLIKACION NGA KLIENTI?</h4>
            <br />
            <br />
            <p>
              Si fillim duhet te kemi kujdes gjithnje dhe te sigurohemi qe aplikacioni eshte i hapur, pra syri lart djthtas.<br />
              Ne castin qe Klienti ben porosi per taxi, porosia i shkon shofereve qe jane afer ne zonen e klientit .<br />
              Shofer merr nje njoftim ne telefonin e tij me nje tingull zanor, si dhe nje njoftim vizual. Ne aplikcaion do shfaqet nje tabele informuese per udhetimin e kerkuar nga klienti si dhe butoni  PRANOJ poshte djathtas.<br />
              Ne tabele shfaqet distanca nga vendi qe shoferi ndodhet deri te pika e Nisjes si dhe distanca nga Nisja deri ne Destinacion. Nisja dhe Destinacioni paraqiten edhe me emrin e rruges. Gjithashtu ne tabele shfaqet edhe kostoja e udhetimit e cila i shfaqet gjithashtu edhe klientit<br />
              Shoferi per pak caste llogarit nqs ja vlen cmimi per distancen qe ka mes Vendndoshjes se tij, Nisjes dhe Destinacionit te klientit. Nqs eshte dakord Klikoj  butonin PRANOJ<br />
              Pasi e kam pranuar kerkesen, per te pare Nisjen e klientit klikoj tek ikona me SHTEPI e cila aktivizon google maps, djathtas ne mes te ekranit dhe duke klikuar aty te con tek Nisja e kerkuar. Pasi kemi mberritur tek Klienti, klikojme tek butoni  BACK te telefonit i cili na kthen tek Aplikacioni. Te njjten procedure ndjekim edhe per te shkuar ne Destinacionin e kerkuar te Klientit.<br />
              Ne Tabelen informuese jepet mesatarisht edhe koha se sa i duhet shoferit per te merritur tek Klienti si dhe Koha per te mberritur ne Destinacion. Gjithashtu ne tabele shfaqet edhe distanca e cila zvoglohet gjithnje gjate ofrimit te Nisjes dhe Destinacionit. Klienti gjithashtu shikon ne kohe reale se si shoferi i ofrohet  atij.<br />
              <br />
            </p>
            <ul class="list-group list-group-flush">
              <li class="list-group-item">- Shoferi mund ti dergoje mesazh klientin neperpjmet aplikacionit duke klikuar tek butoni   <i className="fa  fa-comment" style={{ fontSize: "24px" }}></i>  ose ta telefonoje duke klikuar  <i className="fa fa-phone" style={{ fontSize: "24px" }}></i>  </li>
              <li class="list-group-item">– Ne rastet kur Klienti nderron mendje, ose nuk paraqitet, shoferi rreshqet nga e djathta ne te majte Butonin e Kuq  <i className="fa  fa-window-close" style={{ fontSize: "24px" }}></i> </li>
              <li class="list-group-item">- Ne rastin kur Klienti hipen ne makine, Shoferi rreshqet nga e majta ne te djathte butonin Jeshil   per te filluar udhetimin  <i className="fa fa-car" style={{ fontSize: "24px" }}></i> .<br /></li>
              <li class="list-group-item">- Per te shkuar ne destinacionin e kerkuar pa pyetur klientin, Shoferi klikon tek ikona Flamur ne harte e cila aktivizon GoogleMaps e cila te shpien ne destinacion, si dhe koha dhe distanca e mbetur per te mberritur.  </li>
              <li class="list-group-item">- Ne momentin qe mberrijme ne Destinacion, Shoferi rreshqet nga e majta ne te djathte butonin   per te mbaruar udhetimin  <i className="fa fa-check" style={{ fontSize: "24px" }}></i> . </li>
              <li class="list-group-item">- Pas ketij veprimi  Kujdes!  Do shfaqet nje tabele e cila tregon nese Klienti pagoi udhetimin nepermejt aplikacionit apo do paguaje Cash. </li>
              
            </ul>
            <br /><br />
            <p>
              <b>Pune Te Mbare</b><i className="fa  fa-smile" style={{ fontSize: "24px" }}></i><br />
            </p>
            <br /><br />
          </div>

        </Accordion>

      </div>

      <FooterComponent />
    </div>
  );
}

export default HelpPage;
