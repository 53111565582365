import React from "react";
import "./PeopleSaidComponent.css"
import Slider from 'react-animated-slider';
import 'react-animated-slider/build/horizontal.css';
import data from "./data/testimonials.json"
import { withTranslation } from 'react-i18next';
class PeopleSaidComponent extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            data: data
        }
    }


    render() {
        const { t } = this.props;
        return (
            <React.Fragment>
                <section className="testimonials-style-one" >
                    <div className="container">
                        <div className="block-title text-center">
                            <div className="dot-line"></div>
                            <h2 className="light">{t('peopleSaidCompo.PEOPLE_SAID_H2')}</h2>
                        </div>

                        <div className="testimonials">
                            {/* <h1>Testimonials</h1> */}
                            <div className="test-body">
                                <Slider infinite="true" autoplay="3000" slider='slider'>
                                    {this.state.data.map(dt =>
                                        <div className="item" key={Math.random().toString()}>
                                            <img src={process.env.PUBLIC_URL + dt.imageT} alt="im age" />
                                            <div className="name">{dt.fullNameT}</div>
                                            <small className="desig">{dt.professionT}</small>
                                            {/* <div className="share"><i className="fa fa-facebook"></i><i className="fa fa-twitter"></i><i className="fa fa-instagram"></i></div> */}
                                            <p>{dt.descriptionT}</p>
                                        </div>
                                    )}
                                </Slider>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}
export default withTranslation()(PeopleSaidComponent);

