import React from "react";
import { Link } from 'react-router-dom';
import "./TaxiFareComponent.css"
import data from "./data/data.json"
import { withTranslation } from 'react-i18next';
class TaxiFareComponent extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            data: data
        }
    }


    render() {
        const { t } = this.props;
        return (
            <React.Fragment>
                <hr className="style-one" />
                <section className="taxi-fare-one">
                    <div className="container">
                        <div className="block-title text-center">
                            <div className="dot-line"></div>
                            <p>{t('taxiFareCompo.TAXI_FARE_TITLE_P')}</p>
                            <h2>{t('taxiFareCompo.TAXI_FARE_TITLE_H2')}</h2>
                        </div>
                        <div className="row">
                            {this.state.data.map(dt =>
                                <div className="col-lg-12" key={Math.random().toString()}>
                                    <div className="single-taxi-fare-one thm-base-bg hvr-float-shadow">
                                        <div className="top-block">
                                            <div className="icon-block">
                                                <i className="conexi-icon-taxi"></i>
                                            </div>
                                            <div className="text-block">
                                                <h3>{t('taxiFareCompo.TAXI_FARE_H3')}</h3>
                                                <p>{t('taxiFareCompo.TAXI_FARE_P')}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-5">
                                                <ul className="tick-list features-list" >
                                                    {dt.TAXI_FARE_UL.map(tful =>
                                                        <li key={Math.random().toString()}>
                                                            <div className="name-line"><pre>{tful.TAXI_FARE_UL_NAME}</pre></div>
                                                            <div className="price-line">{tful.TAXI_FARE_UL_PRICE_START}</div>
                                                            <div className="price-line">{tful.TAXI_FARE_UL_PRICE_KM}</div>
                                                        </li>
                                                    )}
                                                </ul>
                                            </div>
                                            <div className="col-lg-1" >

                                            </div>
                                            <div className="col-lg-5">
                                                <ul className="tick-list features-list">
                                                    {dt.TAXI_FARE_UL2.map(tful =>
                                                        <li key={Math.random().toString()}>
                                                            <div className="name-line"><pre>{tful.TAXI_FARE_UL_NAME}</pre></div>
                                                            <div className="price-line">{tful.TAXI_FARE_UL_PRICE_START}</div>
                                                            <div className="price-line">{tful.TAXI_FARE_UL_PRICE_KM}</div>
                                                        </li>
                                                    )}
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="button-block">
                                            <Link className="fare-btn" to="taxilist">{t('taxiFareCompo.TAXI_FARE_TITLE_LINK')}</Link>
                                            {/* <a href="#" className="fare-btn">Order Taxi Now</a>
                                            <a href="tel:+35522249999" className="cta-btn">Call Now</a> */}
                                        </div>
                                    </div>
                                </div>

                            )}


                        </div>
                        <div className="block-text text-center">
                            <p> {t('taxiFareCompo.TAXI_FARE_INFO_P')}</p>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}
export default withTranslation()(TaxiFareComponent);

