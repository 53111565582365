import React from "react";
import "./WhyUsComponents.css"
// import aboutImage from "./images/about-1-1-0.jpg"
import { withTranslation } from 'react-i18next';
class WhyUsComponents extends React.Component {

    constructor(props) {
        super(props)
    }

    render() {
        const { t } = this.props;
        return (
            <React.Fragment>
                <hr className="style-one" />
                <section className="feature-style-one thm-black-bg">
                    {/* <img src={aboutImage} alt="Awesome" className="feature-bg" /> */}
                    <div className="container">
                        <div className="block-title text-center">
                            <div className="dot-line"></div>
                            <p>UPS TAXI</p>
                            <h2 className="light">{t('whyUsCompo.WHY_US')}</h2>
                            <p className="light">{t('whyUsCompo.WHY_US_P')} </p>
                        </div>
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="single-feature-one">
                                    <div className="icon-block">
                                        <i className="conexi-icon-insurance"></i>
                                    </div>
                                    <h3><a href="/">{t('whyUsCompo.WHY_US_TITLE_ONE')}</a></h3>
                                    <p>{t('whyUsCompo.WHY_US_DESCRIPTION_ONE')} </p>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="single-feature-one">
                                    <div className="icon-block">
                                        <i className="conexi-icon-seatbelt"></i>
                                    </div>
                                    <h3><a href="/">{t('whyUsCompo.WHY_US_TITLE_TWO')}</a></h3>
                                    <p>{t('whyUsCompo.WHY_US_DESCRIPTION_TWO')} </p>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="single-feature-one">
                                    <div className="icon-block">
                                        <i className="conexi-icon-consent"></i>
                                    </div>
                                    <h3><a href="/">{t('whyUsCompo.WHY_US_TITLE_THREE')}</a></h3>
                                    <p>{t('whyUsCompo.WHY_US_DESCRIPTION_THREE')} </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}
export default withTranslation()(WhyUsComponents);

