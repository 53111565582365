import React from "react";
import ModalVideo from 'react-modal-video'
import "./AboutComponents.css"
import Image1 from "./images/C1.jpg"
import Image2 from "./images/C2.jpg"
import Image3 from "./images/C3.jpg"
import Image4 from "./images/C4.jpg"
import Image5 from "./images/C5.jpg"
import Image6 from "./images/C6.jpg"
import Image7 from "./images/C7.jpg"
import Image8 from "./images/C8.jpg"
import Image9 from "./images/C9.jpg"
import videoPhoto from "./images/video-1-1.png"
import { Zoom } from 'react-slideshow-image';
import { withTranslation } from 'react-i18next';

class AboutComponents extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            isOpen: false,
            properties: {
                duration: 5000,
                transitionDuration: 500,
                infinite: true,
                indicators: true,
                scale: 0.4,
                arrows: true
            }
        }
        this.openModal = this.openModal.bind(this)
    }



    openModal(e) {
        console.log(e)
        e.preventDefault();
        this.setState({ isOpen: true })
    }


    render() {
        const { t } = this.props;
        return (
            <section className="about-style-one ">
                <div className="container">
                    <div className="block-title text-center">
                        <div className="dot-line"></div>
                        <p>{t('aboutCompo.AB_WELCOME_P')}</p>
                        <h2 style={{ whiteSpace: "pre-line" }}>{t('aboutCompo.AB_WELCOME_H2')}</h2>
                    </div>
                    <div className="row high-gutter">
                        <div className="col-lg-6">
                            <div className="about-image-block">
                                <Zoom {...this.state.properties}>

                                    <img className="img-slide-client" src={Image1} />
                                    <img className="img-slide-client" src={Image2} />
                                    <img className="img-slide-client" src={Image3} />
                                    <img className="img-slide-client" src={Image4} />
                                    <img className="img-slide-client" src={Image5} />
                                    <img className="img-slide-client" src={Image6} />
                                    <img className="img-slide-client" src={Image7} />
                                    <img className="img-slide-client" src={Image8} />
                                    <img className="img-slide-client" src={Image9} />
                                </Zoom>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="text-block">
                                <div className="video-block-one">
                                    <div className="image-block">
                                        <div className="inner-block">
                                            <img src={videoPhoto} alt="Video" ></img>
                                            <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='roHvt9pwJrA' onClose={() => this.setState({ isOpen: false })} />
                                            <a rel="noopener noreferrer" href="/" onClick={this.openModal} className="video-popup"><i
                                                className="fa fa-play" ></i></a>
                                        </div>
                                    </div>
                                    <div className="content-block">
                                        <h3>{t('aboutCompo.AB_WELCOME_H3')}</h3>
                                    </div>
                                </div>
                                <ul>
                                    {t('mobileCompo.BOOK_UL', { returnObjects: true }).map(dt =>
                                        <li key={Math.random().toString()}> <strong>{dt}</strong> </li>
                                    )}
                                </ul>
                                <hr className="style-one" />
                                <div className="download-me">
                                    <ul className="ul">
                                        <li>
                                            <a href="https://play.google.com/store/apps/details?id=com.upstaxi.taxi.rider" rel="noreferrer noopener" target="_blank" >
                                                <div className="download android">
                                                    <i className="fa fa fa-android fa-3x"></i>
                                                    <span className="df">Download from</span>
                                                    <span className="dfn">Google Play</span>
                                                </div>
                                            </a>
                                        </li>
                                        <li>

                                            <a href="https://apps.apple.com/us/app/ups-taxi/id1477752265" rel="noreferrer noopener" target="_blank" >
                                                <div className="download apple">
                                                    <i className="fa fa fa-apple fa-3x"></i>
                                                    <span className="df">Download from</span>
                                                    <span className="dfn">App Store</span>
                                                </div>
                                            </a>

                                        </li>
                                    </ul>
                                </div>
                                {/* <div className="call-block">  108.167.143.22
                                    <div className="left-block">
                                        <div className="icon-block example-1">
                                            <a href="tel:+35522249999"><i className="conexi-icon-phone-call"></i></a>
                                        </div>
                                        <div className="content-block">
                                            <p style={{ whiteSpace: "pre-line" }}>{t('aboutCompo.AB_WELCOME_CALL_P')}</p>
                                        </div>
                                    </div>
                                    
                                </div> */}
                            </div>
                        </div>
                        {/* <div className="row">
                       <div className="col-lg-4"></div>
                        <div className="col-lg-8">
                            <div className="">
                                <ul className="ul">
                                    <li>
                                        <div className="download android">
                                            <i className="fa fa fa-android fa-3x"></i>
                                            <span className="df">Download from</span>
                                            <span className="dfn">Google Play</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="download apple">
                                            <i className="fa fa fa-apple fa-3x"></i>
                                            <span className="df">Download from</span>
                                            <span className="dfn">App Store</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                       </div> */}
                    </div>
                </div>
            </section>
        )
    }
}
export default withTranslation()(AboutComponents);

