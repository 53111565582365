import React from "react";
import "./privacyComponent.css"
import { withTranslation } from 'react-i18next';
class PrivacyComponent extends React.Component {

    constructor(props) {
        super(props);
    }
    render() {
        const { t } = this.props;
        return (
            <React.Fragment>
            
            <section className="privacy-section">
              
              <article className="privacy-article">
                
             
                <h1>{t('privacyCompo.title')}</h1>
                <br/>
                <br/>                 
                <h4>{t('privacyCompo.title1')} </h4>
                <br/>
                {t('privacyCompo.paragrafs1',{returnObjects: true}).map(dt=>
                    <p key={Math.random().toString()} dangerouslySetInnerHTML={{__html:dt}}></p>                    
                )}
                <br/>
                  
                <h4>{t('privacyCompo.title2')} </h4>
                <br/>
                {t('privacyCompo.paragrafs2',{returnObjects: true}).map(dt=>
                    <p key={Math.random().toString()} dangerouslySetInnerHTML={{__html:dt}}></p>                    
                )}
                
                <br/>
                  
                <h4>{t('privacyCompo.title3')} </h4>
                <br/>
                {t('privacyCompo.paragrafs3',{returnObjects: true}).map(dt=>
                    <p key={Math.random().toString()} dangerouslySetInnerHTML={{__html:dt}}></p>                    
                )}
                
                <br/>
                  
                <h4>{t('privacyCompo.title4')} </h4>
                <br/>
                {t('privacyCompo.paragrafs4',{returnObjects: true}).map(dt=>
                    <p key={Math.random().toString()} dangerouslySetInnerHTML={{__html:dt}}></p>                    
                )}
                
                <br/>
                  
                <h4>{t('privacyCompo.title5')} </h4>
                <br/>
                {t('privacyCompo.paragrafs5',{returnObjects: true}).map(dt=>
                    <p key={Math.random().toString()} dangerouslySetInnerHTML={{__html:dt}}></p>                    
                )}
                
                <br/>
                  
                <h4>{t('privacyCompo.title6')} </h4>
                <br/>
                {t('privacyCompo.paragrafs6',{returnObjects: true}).map(dt=>
                    <p key={Math.random().toString()} dangerouslySetInnerHTML={{__html:dt}}></p>                    
                )}
                
                <br/>
                  
                <h4>{t('privacyCompo.title7')} </h4>
                <br/>
                {t('privacyCompo.paragrafs7',{returnObjects: true}).map(dt=>
                    <p key={Math.random().toString()} dangerouslySetInnerHTML={{__html:dt}}></p>                    
                )}
                
                <br/>
                  
                
              </article>
              </section>
            </React.Fragment>  
           
        )
    }
}
export default withTranslation()(PrivacyComponent);

