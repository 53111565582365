import React from "react";
import "./DriverComponents.css"
import { withTranslation } from 'react-i18next';
class DriverComponents extends React.Component {

    constructor(props) {
        super(props);
    }


    render() {
        const { t } = this.props;
        return (
            <section className="cta-style-two" id="cta-style-two" style={{paddingBottom:"0px"}}>
                <div className="container">
                    <div className="row">
                    <div className="col-lg-2"></div>
                        <div className="col-lg-8">
                            <div className="content-block">
                                <h3>{t('driverCompo.WORK_WITH_US_H3')} </h3>
                                <br />
                                <p style={{ whiteSpace: "pre-line" }}> {t('driverCompo.WORK_WITH_US_P')}</p>
                                <br /> <br />
                                <p style={{ whiteSpace: "pre-line" }}> {t('driverCompo.WORK_WITH_US_P2')}</p>
                                <br /> <br />
                                {/* <h4>{t('driverCompo.WORK_WITH_US_DRIVER_P') } </h4> */}
                                {/* <br /> <br /> */}
                                {/* <ul className="tick-list">
                                    {t('driverCompo.WORK_WITH_US_UL',{returnObjects: true}).map(dt =>
                                        <li key={Math.random()}> <strong>{dt}</strong> </li>
                                    )}
                                </ul> 
                                <br /> <br />
                                <br /> <br />*/}
                                <h5 style={{ whiteSpace: "pre-line" }}> {t('driverCompo.WORK_WITH_US_P3')}</h5> 
                            </div>

                        </div>

                        {/* <div className="col-lg-4">
                            <div className="block--saints-app-download-buttons">
                                <div className="block--saints-app-download-cta"><span>{t('driverCompo.WORK_WITH_US_BUTTON_DOWNOAD') } </span></div>
                                <div className="block--saints-app-download-android"><a rel="noopener noreferrer"  href="https://play.google.com/store/apps/details?id=com.upstaxi.taxi.driver" className="btn--download-android" target="_blank" ><img alt="Get it on Google Play" src="http://nolaprodigital.com/saints/images/Saints Mobile App/download-app-google.png" /></a></div>
                                <div className="block--saints-app-download-ios"><a rel="noopener noreferrer"  href="https://apps.apple.com/us/app/ups-taxi-driver/id1477752552" className="btn--download-ios" target="_blank" ><img alt="Get it on iTunes Store" src="http://nolaprodigital.com/saints/images/Saints Mobile App/download-app-apple.png" /></a></div>
                            </div>
                        </div> */}
                    </div>
                </div>

            </section>
        );
    }
}
export default withTranslation()(DriverComponents);

