import React from 'react';
import { HashRouter, Route } from 'react-router-dom';
import './App.css';
import Home from "./pages/homePage/Home"
import TaxiList from './pages/TaxiListPage/TaxiList';
import About from './pages/AboutPage/About';
import DriverList from './pages/DriverListPage/DriverList';
import ScrollToTop from './components/scrollOnTopComponent/ScrollToTop';
import Contact from './pages/ContactPage/Contact';
import CookieConsent from "react-cookie-consent";
import PrivacyPage from './pages/privacyPage/PrivacyPage';
import HelpPage from './pages/helpPage/HelpPage';

function App() {

  return (
    <HashRouter basename="/">
      <CookieConsent
        location="bottom"
        buttonText="OK"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
       Ups Taxi uses cookies to improve the user-friendliness of the site.
        If you continue to use it, we'll assume you are ok with that.
      </CookieConsent>
      <ScrollToTop />
      <Route exact path="/" component={Home} />
      <Route  path="/taxilist" component={TaxiList} />
      <Route  path="/driverlist" component={DriverList} />
      <Route  path="/about" component={About} />
      <Route  path="/contact" component={Contact} />
      <Route  path="/privacy" component={PrivacyPage} />
      <Route  path="/how-it-work" component={HelpPage} />
    </HashRouter>
  );
}

export default App;
