import React from "react";
import { Link } from 'react-router-dom';
import "./NavBarComponent.css"
import { withTranslation } from 'react-i18next';
class NavBarComponent extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            display: "none"
        };
        this.myfun.bind(this);
    }

    setDisplay = () => {
        if (this.state.display === "none") {
            this.setState({
                display: "block"
            })
        } else {
            this.setState({
                display: "none"
            })
        }
    }

    myfun = function (ele) {
        let offsetTop = document.getElementById(ele).offsetTop;
        window.scrollTo({
            top: offsetTop - 100,
            behavior: "smooth"
        });
    }

    render() {
        const { t } = this.props;
        return (
            <nav className="navbar navbar-expand-lg navbar-light header-navigation stricky" style={{marginTop:"-3px"}}>
                <div className="container clearfix">
                    <div className="logo-box clearfix">
                        <button className="menu-toggler" onClick={() => this.setDisplay()} data-target="#main-nav-bar">
                            <span className="fa fa-bars"></span>
                        </button>
                    </div>
                    <div className="main-navigation" id="main-nav-bar" style={{ display: this.state.display }} >
                        <ul className="navigation-box">
                            <li><Link to="/">{t('navbar.home')}</Link></li>
                            <li><Link to="/about">{t('navbar.about')}</Link></li>
                            <li><Link to="/taxilist">{t('navbar.Fleet')}</Link></li>
                            <li><Link to="/driverlist">{t('navbar.drivers')}</Link></li>
                            <li><Link to="/contact">{t('navbar.contact')}</Link></li>
                        </ul>
                    </div>
                    <div className="right-side-box">
                        <a href="tel:+35522249999" className="contact-btn-block">
                            <span className="icon-block">
                                <i className="conexi-icon-phone-call"></i>
                            </span>
                            <span className="text-block">
                                022249999
                            <span className="tag-line">{t('navbar.phone')}</span>
                            </span>
                        </a>
                    </div>
                </div>
            </nav >
        )
    }
}
export default withTranslation()(NavBarComponent);
