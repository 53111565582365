import React from 'react';
import './About.css';
import TopBarComponent from '../../components/topBarComponent/TopBarComponent';
import NavBarComponent from '../../components/navBarComponent/NavBarComponent';
import MainBannerComponent from '../../components/mainBannerComponent/MainBannerComponent';
import imageAbout from "./images/DSC_3288.JPG"
import AvaiableComponent from '../../components/AvaiableComponent/AvaiableComponent';
import FooterComponent from '../../components/FooterComponent/FooterComponent';
import AboutStyleComponents from '../../components/aboutStyleComponents/AboutStyleComponents';
import WhyUsComponents from '../../components/whyUsComponents/WhyUsComponents';
import HistoryComponents from '../../components/historyComponents/HistoryComponents';
import PeopleSaidComponent from '../../components/PeopleSaidComponent/PeopleSaidComponent';
function About() {
   return (
    <div className="page-wrapper">
      <header className="site-header header-one">
        <TopBarComponent />
        <NavBarComponent />
      </header>
      <MainBannerComponent page={imageAbout} description="About UPS Taxi" />     
      <AboutStyleComponents />
      <WhyUsComponents />
      <HistoryComponents />
      <PeopleSaidComponent />
      <AvaiableComponent />
      <FooterComponent />
    </div>
  );
}

export default About;
