import React from "react";
import "react-animated-slider/build/horizontal.css";
// import "normalize.css/normalize.css";
import "./HistoryComponents.css"
// import data from "./data/data.json"
import { withTranslation } from 'react-i18next';
class HistoryComponents extends React.Component {

    constructor(props) {
        super(props)
        // this.state = {
        //     data: data
        // }
    }
    render() {
        const { t } = this.props;
        return (
            <React.Fragment>
                <section className="history-style-one">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="content-block">
                                    <div className="block-title">
                                        {/* <div className="dot-line"></div> */}
                                        <h4>{t('historyCompo.WHAT_OFEER') }</h4>
                                        {/* <h2>Some kinds ....</h2> */}
                                        <p>{t('historyCompo.WHAT_OFEER_DESCRIPTION')}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="content-block">
                                    <div className="block-title">
                                        <div className="dot-line"></div>
                                        <h4>{t('historyCompo.WHAT_OFEER_COMP')}</h4>
                                        <ul className="tick-list">
                                            {t('historyCompo.WHAT_OFEER_DESCRIPTION_COMP',{returnObjects: true}).map(dt=>
                                                 <li key={Math.random().toString()}>{dt}</li>
                                                )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="content-block">
                                    <div className="block-title">
                                        <div className="dot-line"></div>
                                        <h4>{t('historyCompo.WHAT_OFEER_SELF')}</h4>
                                        <ul className="tick-list">
                                            {t('historyCompo.WHAT_OFEER_DESCRIPTION_SELF',{returnObjects: true}).map(dt=>
                                                 <li key={Math.random().toString()}>{dt}</li>
                                                )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="content-block">
                                    <div className="block-title">
                                        <div className="dot-line"></div>
                                        <h4>{t('historyCompo.WHAT_OFEER_CLIENT')}</h4>
                                        <ul className="tick-list">
                                            {t('historyCompo.WHAT_OFEER_DESCRIPTION_CLIENT',{returnObjects: true}).map(dt=>
                                                 <li key={Math.random().toString()}>{dt}</li>
                                                )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}
export default  withTranslation()(HistoryComponents);

