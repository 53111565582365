import React from 'react';
import './DriverList.css';
import TopBarComponent from '../../components/topBarComponent/TopBarComponent';
import NavBarComponent from '../../components/navBarComponent/NavBarComponent';
import MainBannerComponent from '../../components/mainBannerComponent/MainBannerComponent';
import imageTaxList from "./images/DSC_3288.JPG"
import FooterComponent from '../../components/FooterComponent/FooterComponent';
import DriverListComponent from '../../components/driverListComponent/driverListComponent'
function DriverList() {


  return (
    <div className="page-wrapper">
      <header className="site-header header-one">
        <TopBarComponent />
        <NavBarComponent />
      </header>
      <MainBannerComponent page={imageTaxList} description="Our Drivers" />     
      <DriverListComponent />
      <FooterComponent />
    </div>
  );
}

export default DriverList;
