import React from "react";
// import { Link } from 'react-router-dom';
import "./ImageSLiderComponent.css"
import { withTranslation } from 'react-i18next';
import Carousel from "react-elastic-carousel";
class ImageSLiderComponent extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            images: this.props.images,
            breakPoints: [
                { width: 1, itemsToShow: 1 },
                { width: 550, itemsToShow: 2 },
                { width: 768, itemsToShow: 3 },
                { width: 1200, itemsToShow: 4 }
            ]
        }
    }


    render() {
        const { t } = this.props;
        return (
            <React.Fragment>
                <section className="cta-style-one text-center">
                    <div className="container">
                        <p>{t('availableCompo.CALL_P2')}</p>

                        <br />
                        <br />
                        <br />
                        <Carousel enableAutoPlay autoPlaySpeed={2000}
                            // easing="cubic-bezier(1,.15,.55,1.54)"
                            // tiltEasing="cubic-bezier(0.110, 1, 1.000, 0.210)"
                            transitionMs={700} breakPoints={this.state.breakPoints}>

                            {Array.apply(null, { length: this.state.images }).map((value, index) =>
                                <div className="col-lg-12" key={index}>
                                    <div className="single-team-one">
                                        <div className="polaroid-list">
                                            <img className="image-size-list" src={process.env.PUBLIC_URL + "/images/cars/images/Makin" + (index + 1) + ".jpg"} alt="5 Terre" />
                                            {/* <div className="container">
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Carousel>
                    </div>
                </section>
            </React.Fragment>
        )
    }
}
export default withTranslation()(ImageSLiderComponent);

