import React from "react";
import "./AboutStyleComponents.css"
import aboutImage from "./images/about-1-1-0.jpg"
import { withTranslation } from 'react-i18next';
class AboutStyleComponents extends React.Component {

    constructor(props) {
        super(props)
    }




    render() {
        const { t } = this.props;
        return (
            <section className="about-style-two">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="content-block">
                                <div className="block-title">
                                    <div className="dot-line"></div>
                                    <p>{t('aboutStyleCompo.UPS_TAXI_TITLE')}</p>
                                    {/* <h2>Learn about our <br /> taxi company</h2> */}
                                </div>

                                <h3>{t('aboutStyleCompo.UPS_TAXI')}</h3>
                                <p>{t('aboutStyleCompo.UPS_TAXI_DESCRIPTION')}</p>
                                <hr />
                                <h3>{t('aboutStyleCompo.PASSENGER_SAFETY')}</h3>
                                <p>{t('aboutStyleCompo.PASSENGER_SAFETY_DESCRIPTION')}</p>
                                <hr />
                                <h3>{t('aboutStyleCompo.HOW_WORKS')}</h3>
                                <ul>
                                    {t('aboutStyleCompo.HOW_WORKS_DESCRIPTION',{returnObjects: true}).map(dt =>
                                        <li key={Math.random().toString()}>{dt} </li>
                                    )}
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-1"></div>
                        <div className="col-lg-6">
                            <div className="hvr-float-shadow">
                                <div className="image-block image-contain">
                                    <img src={aboutImage} alt="Awesome" />
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
export default withTranslation()(AboutStyleComponents);

