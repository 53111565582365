import React from 'react';
import './Contact.css';
import TopBarComponent from '../../components/topBarComponent/TopBarComponent';
import NavBarComponent from '../../components/navBarComponent/NavBarComponent';
import MainBannerComponent from '../../components/mainBannerComponent/MainBannerComponent';
import imageAbout from "./images/DSC_3288.JPG"
import FooterComponent from '../../components/FooterComponent/FooterComponent';
import GoogleMaps from '../../components/Components/GoogleMaps';
function Contact() {


   return (
    <div className="page-wrapper">
      <header className="site-header header-one">
        <TopBarComponent />
        <NavBarComponent />
      </header>
      <MainBannerComponent page={imageAbout} description="Contact Us" /> 
      <GoogleMaps />      
      <FooterComponent />
    </div>
  );
}

export default Contact;
