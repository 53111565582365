import React from "react";
import "./MobileComponentSecond.css"
import { Zoom } from 'react-slideshow-image';
import Image1 from "./images/D1.jpg"
import Image2 from "./images/D2.jpg"
import Image3 from "./images/D3.jpg"
import Image4 from "./images/D4.jpg"
import Image5 from "./images/D5.jpg"
import Image6 from "./images/D6.jpg"
import Image7 from "./images/D7.jpg"
import Image8 from "./images/D8.jpg"
import { withTranslation } from 'react-i18next';
class MobileComponentSecond extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            properties: {
                duration: 5000,
                transitionDuration: 500,
                infinite: true,
                indicators: true,
                scale: 0.4,
                arrows: true
            }
        };
    }


    render() {
        const { t } = this.props;
        return (
            <section className="cta-style-three">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 d-flex">
                            <div className="my-auto image-block-wrapper text-center">
                                <div className="image-block">
                                    <Zoom {...this.state.properties}>

                                        <img className="img-slide-client" src={Image1} />
                                        <img className="img-slide-client" src={Image2} />
                                        <img className="img-slide-client" src={Image3} />
                                        <img className="img-slide-client" src={Image4} />
                                        <img className="img-slide-client" src={Image5} />
                                        <img className="img-slide-client" src={Image6} />
                                        <img className="img-slide-client" src={Image7} />
                                        <img className="img-slide-client" src={Image8} />
                                    </Zoom>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="content-block">
                                <div className="block-title">
                                    {/* <div className="dot-line"></div> */}
                                    {/* <p>{t('mobileCompo.BOOK_P')}</p> */}
                                    <h2 style={{ whiteSpace: "pre-line" }}>{t('driverCompo.WORK_WITH_US_DRIVER_P')}</h2>
                                </div>
                                <ul className="tick-list">
                                    {t('driverCompo.WORK_WITH_US_UL', { returnObjects: true }).map(dt =>
                                        <li key={Math.random()}> <strong>{dt}</strong> </li>
                                    )}
                                </ul>
                                <div className="button-block">
                                    <a rel="noopener noreferrer" target="_blank" href="https://apps.apple.com/us/app/ups-taxi-driver/id1477752552"
                                        className="app-btn google-btn">
                                        <i className="fa fa-apple icon"></i>
                                        <span className="text-block">
                                            <span className="tag-line">Download From</span>
                                            <span className="store-name">Apple Store</span>
                                        </span>
                                    </a>
                                    <a rel="noopener noreferrer" target="_blank"
                                        href="https://play.google.com/store/apps/details?id=com.upstaxi.taxi.driver"
                                        className="app-btn google-btn">
                                        <i className="fa fa-android icon"></i>
                                        <span className="text-block">
                                            <span className="tag-line">Download From</span>
                                            <span className="store-name">Goolge Play</span>
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <div className="col-lg-12">
                        <div className="col-lg-2"></div>
                            <div className="col-lg-8">
                                {/* <p style={{ whiteSpace: "pre-line" }}> {t('driverCompo.WORK_WITH_US_P3')}</p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
export default withTranslation()(MobileComponentSecond);

