import React from "react";
import "./TopBarComponent.css"
import logo_small from "./images/android-chrome-384x384.png"
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
class TopBarComponent extends React.Component {
    state = {};

    handleClick(e,lang) {
        e.preventDefault();
        i18next.changeLanguage(lang)
      }

    render() {
        return (
            <div className="top-bar">
                <div className="container">
                    <div className="left-block">
                        {/* <a rel="noopener noreferrer" target="_blank" href="https://www.instagram.com/upstaxi/"><i
                                className="fa fa-user-circle"></i> Customer Sign In</a> */}
                        {/* <a rel="noopener noreferrer" href="mailto:info@upstaxi.com?subject=Information about ....&body=Hello Ups'taxi Team,\n\n"><i
                                className="fa fa-envelope"></i> info@upstaxi.com</a> */}
                    </div>
                    <div className="logo-block">
                        <a rel="noopener noreferrer" href="/"><img src={logo_small} alt="Logo" /></a>
                    </div>
                    <div className="social-block">
                        <a rel="noopener noreferrer" href="mailto:info@upstaxi.com?subject=Information about ....&body=Hello Ups'taxi Team,\n\n"><i
                                className="fa fa-envelope"></i></a>
                                 <a rel="noopener noreferrer" target="_blank" href="https://www.instagram.com/upstaxi/"><i
                                className="fa fa-instagram"></i></a>
                        <a rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/upstaxi"><i className="fa fa-facebook-f"></i></a>
                        <a rel="noopener noreferrer" target="_blank" href="https://www.youtube.com/channel/UCODKKtgLlOGPLgSxn3mCTQQ/featured"><i
                                className="fa fa-youtube-play"></i></a>
                                <a rel="noopener noreferrer" href="/"  onClick={(e) => this.handleClick(e,'en')}><i className="fa fa-language"></i> English</a>
                                <a rel="noopener noreferrer" href="/"  onClick={(e) => this.handleClick(e,'al')}><i className="fa fa-language"></i> Shqip</a>
                    </div>
                </div>
            </div>
        )
    }
}
export default withTranslation()(TopBarComponent);
