import React from 'react';
import './PrivacyPage.css';
import TopBarComponent from '../../components/topBarComponent/TopBarComponent';
import MainBannerComponent from '../../components/mainBannerComponent/MainBannerComponent';
import imageHome from "./images/DSC_3288.JPG"
import FooterComponent from '../../components/FooterComponent/FooterComponent';
import NavBarComponent from '../../components/navBarComponent/NavBarComponent';
import PrivacyComponent from '../../components/privacyComponent/privacyComponent';

function PrivacyPage() {

  return (
    <div className="page-wrapper">
      <header className="site-header header-one">
        <TopBarComponent />
        <NavBarComponent />
      </header>
      <MainBannerComponent page={imageHome} opacity={0.5} description="Welcome :)" />
      <PrivacyComponent />
      <FooterComponent />
    </div>
  );
}

export default PrivacyPage;
