import React from 'react';
import './Home.css';
import TopBarComponent from '../../components/topBarComponent/TopBarComponent';
import MainBannerComponent from '../../components/mainBannerComponent/MainBannerComponent';
import imageHome from "./images/DSC_3288.JPG"
import BookComponents from '../../components/bookComponents/BookComponents';
import AboutComponents from '../../components/aboutComponents/AboutComponents';
import DriverComponents from '../../components/driverComponent/DriverComponents';
import TaxiListComponent from '../../components/TaxiComponent/TaxiListComponent';
import TaxiFareComponent from '../../components/TaxiFareComponent/TaxiFareComponent';
import PeopleSaidComponent from '../../components/PeopleSaidComponent/PeopleSaidComponent';
import AvaiableComponent from '../../components/AvaiableComponent/AvaiableComponent';
import FooterComponent from '../../components/FooterComponent/FooterComponent';
import MobileComponentSecond from '../../components/mobileComponentSecond/MobileComponentSecond';
import NavBarComponentHome from '../../components/navBarComponentHome/NavBarComponentHome';

function App() {

  return (
    <div className="page-wrapper">
      <header className="site-header header-one">
        <TopBarComponent />
        <NavBarComponentHome />
      </header>
      <MainBannerComponent page={imageHome} opacity={0.5} description="Welcome :)" />
      <BookComponents />
      <AboutComponents />
      <DriverComponents />
      <MobileComponentSecond />
      <TaxiListComponent />
      <TaxiFareComponent />
      <PeopleSaidComponent />
      <AvaiableComponent />
      <FooterComponent />
    </div>
  );
}

export default App;
